import axios, { AxiosInstance } from "axios";
import Error from "component/error";
import V1AbstractErrorDTO from "dto/v1/v1abstracterror.dto";
import V1ErrorNotImplementedDTO from "dto/v1/v1errornotimplemented.dto";
import V1ErrorUnauhtorizedDTO from "dto/v1/v1errorunauthorized.dto";
import { Store } from "redux";
import { RestrictionType } from "type/restriction.type";

export const axiosConfig: AxiosInstance = axios.create({
  baseURL: "https://api-dev.metropark.dk"
});

export const setupInterceptors = (store: Store) => {
  axiosConfig.interceptors.request.use((config) => {
    let authorizationHeader: string = "";

    if (localStorage.getItem("authentication") !== null) {
      authorizationHeader = "Bearer " + localStorage.getItem("authentication");
    }

    if (config.headers !== undefined) {
      config.headers.Authorization = authorizationHeader;

      return config;
    }
  });
  axiosConfig.interceptors.response.use((response) => {
    return response;
  }, (errorData) => {
    let error: Error = {
      errorCode: 0,
      errorMessage: "",
      errorUuid: ""
    };

    if (errorData.response.data !== undefined) {
      let v1AbstractErrorDTO = errorData.response.data as V1AbstractErrorDTO;
      let errorCode = errorData.response.status as number;

      error.errorCode = errorCode;
      error.errorUuid = v1AbstractErrorDTO.uuid;

      switch (errorCode) {
        case 400: {
          error.errorMessage = "Bad Request: " + v1AbstractErrorDTO.message
          break;
        }
        case 401: {
          switch ((v1AbstractErrorDTO as V1ErrorUnauhtorizedDTO).restrictionType) {
            case RestrictionType.ACCOUNTDISABLED: {
              error.errorMessage = "Bruger er deaktiveret";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.ACCOUNTNOTACTIVATED: {
              error.errorMessage = "Bruger er ikke aktiveret";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.ACCOUNTEXPIRED: {
              error.errorMessage = "Bruger er udløbet";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.ACCOUNTISACTIVATED: {
              error.errorMessage = "Bruger er allerede aktiveret";

              break;
            }
            case RestrictionType.ACCOUNTLOCKED: {
              error.errorMessage = "Bruger er låst";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.AUTHORITY: {
              error.errorMessage = "Bruger har ikke tilstrækkelige rettigheder til dette";

              break;
            }
            case RestrictionType.BADAUTHENTICATION: {
              error.errorMessage = "Ugyldig brugerdata";

              break;
            }
            case RestrictionType.CREDENTIALS: {
              error.errorMessage = "Brugernavn eller adgangskode er forkert";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.CREDENTIALSEXPIRED: {
              error.errorMessage = "Din adgangskode er forældet. Nulstil venligst adgangskode";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.OWNERSHIP: {
              error.errorMessage = "Du ejer ikke dette, og kan derfor ikke se det!"

              break;
            }
            case RestrictionType.ROLE: {
              error.errorMessage = "Bruger har forkert rolle til dette";

              break;
            }
            case RestrictionType.TOKENEXPIRED: {
              error.errorMessage = "Token er udløbet";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.TOKENDISABLED: {
              error.errorMessage = "Token er deaktiveret";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.TOKENTYPEINVALID: {
              error.errorMessage = "Token er ugyldigt";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            case RestrictionType.TOKENUNVERIFIED: {
              error.errorMessage = "Token kunne ikke blive verificeret, log venligst ind igen";

              store.dispatch({
                type: "authentication/logout",
                errorCode: error.errorCode,
                errorMessage: error.errorMessage,
                errorUuid: error.errorUuid
              });

              break;
            }
            default: {
              error.errorMessage = "Ukendt 401 fejl"

              break;
            }
          }

          break;
        }
        case 403: {
          error.errorMessage = "Du er ikke logget ind";

          store.dispatch({
            type: "authentication/logout",
            errorCode: error.errorCode,
            errorMessage: error.errorMessage,
            errorUuid: error.errorUuid
          });

          break;
        }
        case 404: {
          error.errorMessage = "Ikke fundet";
          break;
        }
        case 501: {
          error.errorMessage = "Funktionen \"" + (v1AbstractErrorDTO as V1ErrorNotImplementedDTO).methodName + "\" i \"" + (v1AbstractErrorDTO as V1ErrorNotImplementedDTO).className + "\" er ikke implementeret endnu";
          break;
        }
        default: {
          error.errorMessage = "Ukendt fejl";
          break;
        }
      }
    } else {
      error.errorMessage = "Ingen forbindelse til server"
      store.dispatch({
        type: "authentication/logout",
        errorCode: error.errorCode,
        errorMessage: error.errorMessage,
        errorUuid: error.errorUuid
      });
    }

    return Promise.reject(error);
  });
}